import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      foreignCurrency: 'configuration/foreignCurrency',
      foreignCurrencyExchangeRate: 'configuration/foreignCurrencyExchangeRate'
    })
  },
  methods: {
    getPrice (prices, pricingGroupId) {
      const pricingGroupPrice = prices.find((p) => p.pricingGroupId === pricingGroupId)
      if (pricingGroupPrice) {
        return pricingGroupPrice
      } else {
        const defaultPrice = prices.find((p) => p.pricingGroupId === null)
        return defaultPrice || null
      }
    },
    applyCurrencyExchange (amount, currencyId) {
      if (currencyId === this.foreignCurrency.id) {
        return amount / this.foreignCurrencyExchangeRate
      } else {
        return amount || 0
      }
    }
  }
}
